<template>
  Adjusted Revenue
  <span
    v-tooltip="'Sum of Sales Royalties + KENP Royalties multiplied by the Revenue Multiplier.'"
    class="pi pi-info-circle mx-1"
  />
</template>

<script>
export default {};
</script>
